import React from "react";
import "./section2.scss"
import {Button, Col, Row} from "antd"
import { ArrowDownOutlined } from '@ant-design/icons';
import Circle from "../../assets/icons/recan-1.svg"
import Builder from "../../assets/icons/recan-2.svg"
import User from "../../assets/icons/recan-3.svg"
import GooglePlay from "../../assets/icons/google-play.svg"
import AppStore from "../../assets/icons/app-store.svg"

const style = {
    padding: '7% 0',
    background:"#fff",
    borderRadius:"24px",
    height:"530px"
  };
    let width = window.innerWidth;
  const goTo = () =>{
    window.location.href = "/coming-soon"
  }
function Section2(){
    return(
        <div className="section2" id="section2">
            <h1 className="heading-1">All you need to get started</h1>
            {/* <Row 
            className="rectangle-1"
            >
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <img src={Circle} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <h1 className="heading-2">
                        Become an investor
                    </h1>
                    <p>Simply connect your MetaMask wallet, send the appropriate amount of 
                      ETH and behold, you are now the proud owner of MeshCoin ERC20. (Easy-Peasy)
                    </p>
                    <Button className="investingButton" href="./coming-soon">Start investing</Button>
                </Col>
            </Row>   */}
    <Row gutter={16}
    //  className="lower-row"  
     style={width <= 767 ? {margin:"25px 10px"} : {margin : "25px 50px"}}>
            <Col className="triangle-3" xs={24} sm={24} md={12} lg={12} xl={12}>
      <div  className="inner-div">
            <h1>Become a user</h1>
            <p>
            Download the OpenMesh app via <b>Ali Express</b>.Buy one of the supported 
            transceivers and install the OpenMesh firmware.
            </p>
            <img src={User} />
            {/* <br/>
            <img src={GooglePlay} onClick={goTo}/>
            <img src={AppStore} onClick={goTo}/> */}
        </div>
      </Col>
      <Col className="triangle-2" xs={24} sm={24} md={12} lg={12} xl={12} >
        <div className="inner-div">
            <h1>Become a network builder</h1>
            <p>
              Purchase a device, download the OpenMesh firmware, install the firmware with some 
              simple instructions and you’ll be in business in no time! The more messages flowing
              in your nodes, the more rewards earned. (Yes, it’s that simple)
            </p>
            <img src={Builder} />
            <br/>
            <Button className="buyButton" href="https://rb.gy/na4uob" target="_blank">Buy device</Button>
        </div>
      </Col>
    </Row>
        </div>
    )
}
export default Section2;