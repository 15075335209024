import React from "react";
import {Row , Col  , Layout, Button} from "antd"
import "./footer.scss"
import Logo from "../../assets/icons/whiteLogo.svg"
import GooglePlay from "../../assets/icons/google-play.svg"
import GitHub from "../../assets/icons/github-one.svg"

const { Footer } = Layout;

const FooterMain = ({setIsModalVisible}) => {
    const showModal = () => {
        setIsModalVisible(true);
      };
    const goTo =() =>{
        window.location.href = "./coming-soon"
    }
    const goToPrivacy =() =>{
        window.location.href = "/privacy-policy"
    }
    return(
        <div className="footer" 
        // style={window.location.href.includes("coming") && window.innerWidth > 1900 ? {marginTop:"300px"} : {}}
        >
            <Row>
                <Col  xs={24} sm={24} md={24} lg={10} xl={10} >
                    <img src={Logo} style={{cursor:"text"}}/>
                    <p style={{marginTop:"15px"}}>
                        OpenMesh is a community owned and operated network that uses
                         public band LoRA radios to create a peer to peer text service 
                         usable via mobile phones and other IoT devices. Imagine text chats 
                         without the cellular service...straight from your phone.
                    </p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} >
                    <h3 style={{fontWeight:"600",cursor:"text"}}>OpenMesh</h3>
                    {/* <h3 onClick={goTo}>About</h3> */}
                    <h3 onClick={goToPrivacy}>Privacy Policy</h3>
                    <h3 
                    // onClick={showModal}
                     ><a href="https://discord.gg/QGayCqh7QA" target="_blank" style={{color:"#FFF"}}> Contact us</a></h3>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} > 
                    <h3 style={{fontWeight:"600", paddingBottom:10 , cursor:"text"}}>Find us on</h3>
                    <Button className="github-button" href="http://bitly.ws/zmVu" target="_blank"><img src={GitHub} /> Github</Button>
                    {/* <img src={GooglePlay} style={{marginRight:15}} onClick={goTo}/> */}
                    {/* <img src={AppStore}  onClick={goTo}/> */}
                </Col>
            </Row>
            <Footer id="hello" style={{ textAlign: 'center' }}>Copyright © 2023 All Rights Reserved - OpenMesh</Footer>
        </div>
    )
}
export default FooterMain;