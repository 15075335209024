import React  from "react";
import "./section7.scss"
import {Button} from "antd"
import Discord from "../../assets/icons/discord.svg"
function Section7({setIsModalVisible}){
    const showModal = () => {
    //   setIsModalVisible(true);
    window.location.href="https://discord.gg/QGayCqh7QA"
    };
    return(
        <div className="section7">
            {/* <h1>We’d love to tell you more!</h1> */}
            <h1>Connect with us!</h1>
            {/* <p>Drop us a message with your query or suggestion and we’ll get back to you as soon as possible.</p> */}
            <p>Join our discord server and become a part of the OpenMesh community.</p>
            <Button className="facilitatorButton" 
            // onClick={showModal} 
            href={"https://discord.gg/QGayCqh7QA"} target="blank">
                <img src={Discord} />
                Join OpenMesh Server</Button>
        </div>
    )
}
export default Section7;