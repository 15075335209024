import React from "react";
import "./section1.scss"
import {Button} from "antd"
import { ArrowDownOutlined } from '@ant-design/icons';
import mainImage from "../../assets/images/Header_BG_1.png"
function Section1(){
    return(
        <div className="section1">
        <div className="div-1">
            <h3>MEET OPENMESH</h3>
            <h1>Decentralized Messaging</h1>
            <Button className="exploreButton" href="/#section2">Explore more <ArrowDownOutlined /></Button>
        </div>
        <img src={mainImage} />
        </div>
    )
}
export default Section1;