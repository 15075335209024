import React from 'react';
import "./navbar.scss"
import Logo from "../../assets/icons/Logo.svg"
import { Row, Col, Layout, Menu, Button  } from 'antd';
import { HashLink as Link } from "react-router-hash-link";
const { Header } = Layout;

const Navbar = () => {

  const goToLandingPage = () =>{
    window.location.href = "./"
  }
  return(
    <div className='topBar'>
    <Header
      style={{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
      }}
    >
        <Row>
            <Col xs={18} sm={18} md={7} lg={7} xl={7}>
                <img src={Logo} onClick={goToLandingPage} style={{cursor:"pointer"}}/>
            </Col>
            <Col xs={6} sm={6} md={10} lg={10} xl={10}>
                <Menu
                    theme="light"
                    mode="horizontal"
                >
                    <Menu.Item key={"product"}>
                      {/* <a href='https://rb.gy/na4uob'>Product</a> */}
                      <Link to="/#section3">Product</Link>
                    </Menu.Item>
                    <Menu.Item key={"reward"}>
                      <a href="./explore-more">Documentation</a>
                    </Menu.Item>
                    <Menu.Item key="app">
                      <a href='./coming-soon'>Mobile App</a>
                    </Menu.Item>
                    <Menu.Item key={"faq"}>
                      <Link to="/#faqs">FAQs</Link>
                    </Menu.Item>
                </Menu>
            </Col>
            <Col span={7}>
                {/* <Button className="loginButton">Login</Button> */}
            </Col>
        </Row>
    </Header>
    </div>
      )
  }
export default Navbar;