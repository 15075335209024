import React from "react";
import "./section6.scss"
import {Button} from "antd"
import backgroundImage from "../../assets/images/Rectangle.svg"
function Section6(){
    const [width, setWidth] = React.useState("82%");
    const [borderRadius , setBorderRadius] = React.useState()
    const handleScroll = () => {
        const position = window.pageYOffset;
        if(position  > 1800 && position < 2500){
          // if(position  > 1000 && position < 1800){

            setWidth("100%")
            setBorderRadius("0px")
        }
        else{
            setWidth("82%")
            setBorderRadius("24px")
        }
      };
      React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, [width]);
    return(
        <div className="section6" style={window.innerWidth > 1900 ? {height:"700px"} : {height:"580px"}}>
          <header>
            <img src={backgroundImage} style = {{width: `${width}`, borderRadius : borderRadius
            // width !== "100%" ? "24px" : "0%"
            }} />
            <section>
              <h1>Get started with <span>OpenMesh</span> today</h1>
              <p>Buy your own OpenMesh hardware device. You can also Download the OpenMesh app for your phone</p>
              <Button className="buyDeviceButton" href="https://rb.gy/na4uob" target="_blank">Buy device</Button> 
            </section>
        </header>
        </div>
    )
}
export default Section6;