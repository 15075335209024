import React from 'react';
import '../../App.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {  Layout} from 'antd';
import { useLocation } from 'react-router-dom';
import Section1 from '../section1/section1';
import Section2 from '../section2/section2';
import Section3 from '../section3/section3';
import Section4 from '../section4/section4';
import Section5 from '../section5/section5';
import Section6 from '../section6/section6';
import Section7 from '../section7/section7';
// import Footer from '../footer/footer';
import Section8 from '../section8/section8';
// import MessageModal from '../message-modal/message-modal';
import PrivacyPolicy from '../privacy-policy/privacy-policy-component';
const { Content } = Layout;
function LandingPage({setIsModalVisible,isModalVisible}) {
  const location = useLocation();
  React.useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);
  return (
    <div className="App">
      <Layout className='topBar'>
         <Content className="site-layout" style={{ marginTop: 64 }}>
          <Section1 />
          <Section2 />
          <Section3 />
          {/* <Section4 /> */}
          {/* <Section5 /> */}
          <Section6 />
          <Section8 setIsModalVisible={setIsModalVisible}/>
          <Section7 isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}/> 
        </Content> 
      </Layout>
    </div>
  );
}

export default LandingPage;
