import React, { useRef, useState, useEffect } from "react";
import "./section3.scss"
import { Button, Col, Row, Collapse, Carousel } from "antd"
import { ArrowDownOutlined } from '@ant-design/icons';
import Accordion1 from "../../assets/images/accordion1.svg"
import Accordion2 from "../../assets/images/accordion2.svg"
import Accordion3 from "../../assets/images/accordion3.svg"
import Accordion4 from "../../assets/images/accordion4.svg"
import Accordion5 from "../../assets/images/accordion5.svg"
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};
function Section3() {
    const carouselRef = React.createRef();
    const [accordionKey, setAccordionKey] = React.useState("1");
    const [openPanel, setOpenPanel] = React.useState(1)
    const test = (e) => {
        if (e === "2") {
            carouselRef.current.goTo(1)
        } else if (e === "3") {
            carouselRef.current.goTo(2)
        } else if (e === "4") {
            carouselRef.current.goTo(3)
        }
        else if (e === "5") {
            carouselRef.current.goTo(4)
        }
        else {
            carouselRef.current.goTo(0)
        }
        setOpenPanel(e)
        // setAccordionKey(e)
    }
    React.useEffect(() => {
        localStorage.removeItem("accordionKey")
    }, [accordionKey, openPanel])
    React.useEffect(() => {
        setInterval(() => {
        }, 3100);
    }, [accordionKey])

    return (
        <div className="section3" id="section3">
            <h1>Based on Heltec LoRa ESP32</h1>
            <h3>WiFi LoRa 32 is a classic IoT dev-board designed &
                produced by Heltec Automation(TM)
            </h3>

            <div className="mobileCarousel"
            >
                <Carousel
                    autoplay
                    style={{ textAlign: "-webkit-center" }} dots={true}
                >
                    <Row style={contentStyle}>
                        <Col span={8}>                        <img src={Accordion1} />
                        </Col>
                        <Col span={8}>                        <h3>ESP32 Chip</h3>
                        </Col>
                        <Col span={8}>                        <p>ESP32 (dual-core 32-bit MCU + ULP core), with LoRa node chip SX1276/SX1278;</p>
                        </Col>


                    </Row>
                    <div>
                        <img src={Accordion2} />
                        <h3>Micro USB Interface</h3>
                        <p>Micro USB interface with a complete voltage regulator, ESD protection, short circuit protection, RF shielding, and other protection measures;</p>

                    </div>
                    <div>
                        <img src={Accordion3} />
                        <h3>OLED Display</h3>
                        <p>Onboard 0.96-inch 128*64 dot matrix OLED display, which can be used to display debugging information, battery power, and other information;</p>

                    </div>
                    <div>
                        <img src={Accordion4} />
                        <h3>Battery Interface</h3>
                        <p>Onboard SH1.25-2 battery interface, integrated lithium battery management system</p>

                    </div>
                    <div>
                        <img src={Accordion5} />
                        <h3>Integrated Wi-Fi and Bluetooth</h3>
                        <p>Integrated WiFi, LoRa, Bluetooth three network connections, onboard Wi-Fi, Bluetooth dedicated 2.4GHz metal 3D antenna, reserved IPEX (U.FL) interface for LoRa use;</p>

                    </div>
                </Carousel>
            </div>

            {/* <div className="test"
>
    <Row>
        <Col span={12}>
        <Carousel 
              autoplay
              style={{textAlign: "-webkit-center"}} dots={true} 
              dotPosition={"right"}
              >
       <Row 
       >
        <img src={Accordion1} />


                        </Row>
                        <Row>
                            <img src={Accordion2} />
                          
                        </Row>
                        <Row>
                            <img src={Accordion3} />
                            
                        </Row>
                        <Row>
                            <img src={Accordion4} />
                          
                        </Row>
                        <div>
                            <img src={Accordion5} />
                        
                        </div>
  </Carousel>
        </Col>
        <Col span={12}>
        <Collapse
                    ghost accordion 
                    //  onChange={test} 
                    activeKey={openPanel} style={{height:350}}>
                    <Panel header="ESP32 Chip" key="1" 
                    showArrow={false}
                    >
                  
                        <p>ESP32 (dual-core 32-bit MCU + ULP core), with LoRa node chip SX1276/SX1278;</p>
                    </Panel>
                    <Panel header="Micro USB Interface" key="2"
                    showArrow={false}
                    >
                
                        <p>Micro USB interface with a complete voltage regulator, ESD protection, short circuit protection, RF shielding, and other protection measures;</p>
                    </Panel>
                    <Panel header="OLED Display" key="3" 
                    showArrow={false}
                    >
                    
                        <p>Onboard 0.96-inch 128*64 dot matrix OLED display, which can be used to display debugging information, battery power, and other information;</p>
                    </Panel>
                    <Panel header="Battery Interface" key="4" 
                    showArrow={false}
                    >
                        <p>Onboard SH1.25-2 battery interface, integrated lithium battery management system</p>
                    </Panel>
                    <Panel header="Integrated Wi-Fi and Bluetooth" key="5"
                    showArrow={false}
       >
                        <p>Integrated WiFi, LoRa, Bluetooth three network connections, onboard Wi-Fi, Bluetooth dedicated 2.4GHz metal 3D antenna, reserved IPEX (U.FL) interface for LoRa use;</p>
                    </Panel>
                    </Collapse>
        </Col>
    </Row>
          
  </div> */}
            <Row className="desktopCarousel">
                <Col span={12} style={{ textAlign: "-webkit-center" }}>
                    <Carousel dots={false} ref={carouselRef}>
                        <div>
                            <img src={Accordion1} />
                        </div>
                        <div>
                            <img src={Accordion2} />
                        </div>
                        <div>
                            <img src={Accordion3} />
                        </div>
                        <div>
                            <img src={Accordion4} />
                        </div>
                        <div>
                            <img src={Accordion5} />
                        </div>
                    </Carousel>
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                    <Collapse
                        ghost accordion
                        onChange={test}
                        activeKey={openPanel} style={{ height: 350 }}>
                        <Panel header="ESP32 Chip" key="1"
                            showArrow={false}
                            className={openPanel === 1 ? "" : "shortLength-1"}>
                            <div className="longLength-1">
                                <span className='main-1'></span>
                            </div>
                            <p>ESP32 (dual-core 32-bit MCU + ULP core), with LoRa node chip SX1276/SX1278;</p>
                        </Panel>
                        <Panel header="Micro USB Interface" key="2"
                            showArrow={false}
                            className={openPanel === 2 ? "" : "shortLength-2"}>
                            <div className="longLength-2">
                                <span className='main-2'></span>
                            </div>
                            <p>Micro USB interface with a complete voltage regulator, ESD protection, short circuit protection, RF shielding, and other protection measures;</p>
                        </Panel>
                        <Panel header="OLED Display" key="3"
                            showArrow={false}
                            className={openPanel === 3 ? "" : "shortLength-3"}>
                            <div className="longLength-3">
                                <span className='main-3'></span>
                            </div>
                            <p>Onboard 0.96-inch 128*64 dot matrix OLED display, which can be used to display debugging information, battery power, and other information;</p>
                        </Panel>
                        <Panel header="Battery Interface" key="4"
                            showArrow={false}
                            className={openPanel === 4 ? "" : "shortLength-4"}>
                            <div className="longLength-4">
                                <span className='main-4'></span>
                            </div>
                            <p>Onboard SH1.25-2 battery interface, integrated lithium battery management system</p>
                        </Panel>
                        <Panel header="Integrated Wi-Fi and Bluetooth" key="5"
                            showArrow={false}
                            className={openPanel === 5 ? "" : "shortLength-5"}>
                            <div className="longLength-5">
                                <span className='main-5'></span>
                            </div>
                            <p>Integrated WiFi, LoRa, Bluetooth three network connections, onboard Wi-Fi, Bluetooth dedicated 2.4GHz metal 3D antenna, reserved IPEX (U.FL) interface for LoRa use;</p>
                        </Panel>
                    </Collapse>
                    {/* <Button className="buyDevice" href="./explore-more">Buy Device</Button> */}
                </Col>
            </Row>
        </div>
    )
}
export default Section3;