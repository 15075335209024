import React from "react";
import "./section8.scss"
import {Button , Col, Collapse, Row} from "antd"
import QuestionMark from "../../assets/icons/helpcenter.svg"
import {MinusCircleOutlined , PlusCircleOutlined} from "@ant-design/icons"

const {Panel }= Collapse;
function Section8({setIsModalVisible}){
      const showModal = () => {
        setIsModalVisible(true);
      };
    return(
        <div className="section8" id="faqs">
            <Row>
                <Col span={4}></Col>
                <Col span={16}>
                    <img src={QuestionMark} />
                    <h2>Frequently Asked Questions</h2>
                    <p>If you still have any questions or are feeling inquisitive, feel free to <a href="https://discord.gg/QGayCqh7QA" target="_blank">connect with us </a>
                    {/* and well get back to you as soon as we can. */}<br/>
                    </p>
                    <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition={"end"}
                     expandIcon={({ isActive }) => isActive ? <MinusCircleOutlined style={{ fontSize: 20, color: "#21BFCA"}}/> 
                     : <PlusCircleOutlined style={{ fontSize: 20 , color : "#9E9E9E"}}/>} 
                     accordion>
                        <Panel header="What if there are no relay sites in my area?" key="1">
                        Don’t worry! You’ll still earn MeshCoin by keeping your transceivers and relays active.
                        Our goal is to publish a network explorer to the community to encourage others to add nodes 
                        close to where other nodes exist. To make this possible, we need to know the location of your 
                        transceivers. The OpenMesh application, when allowed to run in the background, will occasionally 
                        ping nearby transceivers and communicate their ID and geolocation info to the OpenMesh blockchain.
                        You will earn location validation credits (MeshCoin) once an hour for validating the location of 
                        nearby transceivers. The amount you receive will vary on how many transceivers exist in your area; 
                        dense areas get fewer rewards while new, sparse areas get higher rewards.
                        </Panel>
                        <Panel header="Where can I download the OpenMesh firmware?" key="2">
                        Here's the link: <a target={"_blank"} href="http://bitly.ws/zmVu">http://bitly.ws/zmVu</a>
                        </Panel>
                        {/* <Panel header="Where can I download the OpenMesh mobile application?" key="3">
                        Here for iPhone and here for android
                        </Panel>
                        <Panel header="What is the OpenMesh contract address?" key="4">
                        It's:
                        </Panel>
                        <Panel header="What is the current value of MeshCoin?" key="5">
                        Live update of mc price
                        </Panel>
                        <Panel header="Where can I find the OpenMesh network map?" key="6">
                        On our network explorer live map
                        </Panel>
                        <Panel header="How do I setup MetaMask?" key="7">
                        Here is a step by step tutorial
                        </Panel> */}
                    </Collapse>
                </Col>
                <Col span={4}></Col>
            </Row>
        </div>
    )
}
export default Section8;