import React from "react";
import "./coming-soon.scss"
import {Button} from "antd"
import { ArrowDownOutlined } from '@ant-design/icons';
import mainImage from "../../assets/images/Group.png"
function ComingSoon(){
    return(
        // <div className="coming-soon">
        //     <div className="div-1" style={window.innerWidth > 1900 ? {padding: "350px 0px 200px"} : {padding : "140px 0"}}>
        //         {/* <h3>Sorry for this inconvenience </h3> */}
        //         {/* <h1>This page will be available soon</h1> */}
        //         <h1>The app will be available soon</h1>
        //         {/* <span className="span-1">We’re currently working hard on this page so it’ll be live soon</span> */}
        //         <span className="span-1">We’re currently working hard on the app so it’ll be live soon</span>
        //         <br/>
        //         <br/>
        //         <Button className="exploreButton" href="./">Back to homepage</Button>
        //     </div>
        //     <img src={mainImage} style={{
        //         //visibility:"hidden"
        //         // display:"none"
        //         height:"501px"
        //         }}/>
        // </div>
         <div className="coming-soon">
        <div className="div-1">
            {/* <h3>MEET OPENMESH</h3> */}
            <h1>The app will be available soon</h1>
            <p className="span-1">We’re currently working hard on the app so it’ll be live soon</p>
           <Button className="exploreButton" href="./">Back to homepage</Button>
        </div>
        <img src={mainImage} />
        </div>
    )
}
export default ComingSoon;