import React from "react";
import "./explore-more-styling.scss";
import { Col, Layout, Row } from "antd";
import LeftImage from "../../assets/images/left-image.svg"
import RightImage from "../../assets/images/right-image.svg"

const { Content } = Layout;

function ExploreMore() {
  return (
      <Content>
        <div className="explore-more-main">
            <h1>
            Documentation
            </h1>
            <p style={{marginTop:28 , marginBottom:48}}>
            Github: <a href="http://bitly.ws/zmVu" target={'_blank'}>http://bitly.ws/zmVu</a><br/>
            Buy Device: <a href="https://rb.gy/na4uob" target={"_blank"}>https://rb.gy/na4uob</a>
            </p>
            <p>
            We have one OpenMesh device and a Browser which can be referred to as a paired device. Browser will be used for User interface. The OpenMesh device has four parts
            </p>
            <Row>
                <Col span={12}>
                    <img src={LeftImage} />
                </Col>
                <Col span={12} style={{textAlign:"right"}}>
                <img src={RightImage} />
                </Col>
            </Row>
            <ol>
                <li><b>ESP32 MicroController</b> that will be attached with WIFI which is embedded with Controller.</li>
                <li><b>OpenMesh Technology </b>the purpose of this technology that will be used for broadcast messages to air or for other OpenMesh Devices that will be in this device range.</li>
                <li><b>OLED 0.96</b> this will be used for Display.</li>
                <li><b>Memory Storage.</b> We can store the messages or data for using it.</li>
            </ol>
            <h2>How it will work.</h2>
            <p>There is no central server for communicating and storing the messages for network users, all messages will be saved in their own Device. The Device will send and receive messages through OpenMesh Technology and pass messages to controller and controller process the message and display to browser and save device storage .</p>
            <h2 style={{marginTop:80}}>Get Started from here</h2>
            <p style={{marginTop:28 , marginBottom:48}}>
            Github: <a href="http://bitly.ws/zmVu" target={"_blank"}>http://bitly.ws/zmVu</a><br/>
            Buy Device: <a href="https://rb.gy/na4uob" target={"_blank"}>https://rb.gy/na4uob</a>
            </p>
        </div>
      </Content>
  )
}
export default ExploreMore