import React from "react";
import "./privacy-policy-styling.scss";
import {Layout } from "antd";
const { Content } = Layout;

function PrivacyPolicy({ setIsModalVisible, isModalVisible }) {
  return (
      <Content>
        <div className="privacy-policy-main" style={{ paddingTop: 128 }}>
          <h1>Privacy</h1>
          <p style={{ marginTop: "60px" }}>
            Our Privacy Policy was last updated on <b>15 Dec,2022.</b>
            <br />
            <br />
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You. We use Your Personal data to provide and improve the
            Service. By using the Service, You agree to the collection and use
            of information in accordance with this Privacy Policy.
          </p>
          <h2 style={{ marginTop: 79 }}>Interpretation and Definitions</h2>
          <h3 style={{ marginTop: 32 }}>Interpretation</h3>
          <p>
            The words of which the initial letter is capitalised have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h3>Definitions</h3>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <b>"Account"</b> means a unique account created for You to access
              our Service or parts of our Service.
            </li>
            <li>
              <b>"Company"</b> (referred to as either "the Company", "We", "Us"
              or "Our" in this Agreement) refers to [COMPANY INFORMATION]
            </li>
            <li>
              <b>"Country"</b> refers to [COMPANY_COUNTRY]
            </li>
            <li>
              <b>"Cookies"</b> are small files that are placed on Your computer,
              mobile device or any other device by a website, containing the
              details of Your browsing history on that website among its many
              uses
            </li>
            <li>
              <b>"Device"</b> means any device that can access the Service such
              as a computer, a cell phone or a digital tablet
            </li>
            <li>
              <b>"Personal Data"</b> is any information that relates to an
              identified or identifiable individual
            </li>
            <li>
              <b>"Service"</b> refers to the Website
            </li>
            <li>
              <b>"Service Provider"</b> means any natural or legal person who
              processes the data on behalf of the Company.It refers to
              third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analysing how the Service is used
            </li>
            <li>
              <b>"Usage Data"</b> refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit)
            </li>
            <li>
              <b>"Website"</b> refers to [OpenMesh], accessible from
              [WEBSITE_URL]
            </li>
            <li>
              <b>"You"</b> means the individual accessing or using the Service,
              or the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable.
            </li>
          </ul>
          <h2 style={{ marginTop: 70 }}>
            Collecting and Using Your Personal Data
          </h2>
          <h3>Types of Data Collected</h3>
          <h4>Personal Data</h4>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Usage Data</li>
          </ul>
          <h4>Usage Data</h4>
          <p>
            Usage Data is collected automatically when using the Service.
            <br />
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
            <br />
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
            <br />
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <h4>Tracking Technologies and Cookies</h4>
          <p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </p>
          <ul>
            <li>
              <b>Cookies or Browser Cookies.</b> A cookie is a small file placed
              on Your Device. You can instruct Your browser to refuse all
              Cookies or to indicate when a Cookie is being sent. However, if
              You do not accept Cookies, You may not be able to use some parts
              of our Service. Unless you have adjusted Your browser setting so
              that it will refuse Cookies, our Service may use Cookies.
            </li>
            <li>
              <b>Web Beacons.</b> Certain sections of our Service and our emails
              may contain small electronic files known as web beacons (also
              referred to as clear gifs, pixel tags, and single-pixel gifs) that
              permit the Company, for example, to count users who have visited
              those pages or opened an email and for other related website
              statistics (for example, recording the popularity of a certain
              section and verifying system and server integrity).
            </li>
          </ul>
          <p>
            Cookies can be <b>"Persistent"</b> or <b>"Session"</b> Cookies.
            Persistent Cookies remain on Your personal computer or mobile device
            when You go offline, while Session Cookies are deleted as soon as
            You close Your web browser.
          </p>
          <p>
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <h4>
            Necessary / Essential Cookies
            <br />
            Type: Session Cookies
            <br />
            Administered by: Us
          </h4>
          <p>
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </p>
          <h4>
            Cookies Policy / Notice Acceptance Cookies
            <br />
            Type: Persistent Cookies
            <br />
            Administered by: Us
          </h4>
          <p>
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </p>
          <h4>
            Functionality Cookies
            <br />
            Type: Persistent Cookies
            <br />
            Administered by: Us
          </h4>
          <p>
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </p>
          <h4>
            Tracking and Performance Cookies
            <br />
            Type: Persistent Cookies
            <br />
            Administered by: Third-Parties
          </h4>
          <p>
            Purpose: These Cookies are used to track information about traffic
            to the Website and how users use the Website. The information
            gathered via these Cookies may directly or indirectly identify you
            as an individual visitor. This is because the information collected
            is typically linked to a pseudonymous identifier associated with the
            device you use to access the Website. We may also use these Cookies
            to test new pages, features or new functionality of the Website to
            see how our users react to them.
          </p>
          <p>
            For more information about the cookies we use and your choices
            regarding cookies, please visit our <a>Cookies Policy</a> or the
            Cookies section of our <a>Privacy Policy</a>.
          </p>
          <h2 id="faqs">Use of Your Personal Data</h2>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li>
              <b>To provide and maintain our Service,</b> including to monitor
              the usage of our Service.
            </li>
            <li>
              <b>To contact You: </b>To contact You by email, telephone calls,
              SMS, or other equivalent forms of electronic communication, such
              as a mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </li>
            <li>
              <b>To provide You with news,</b> special offers and general
              information about other goods, services and events which we offer
              that are similar to those that you have already purchased or
              enquired about unless You have opted not to receive such
              information.
            </li>
            <li>
              <b>To manage Your requests: </b>To attend and manage Your requests
              to Us.
            </li>
            <li>
              <b>For other purposes: </b>We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing
              and your experience.
            </li>
          </ul>
          <p>
            We may share Your personal information in the following situations:
          </p>
          <ul>
            <li>
              <b>With Affiliates: </b>We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </li>
            <li>
              <b>With business partners: </b>We may share Your information with
              Our business partners to offer You certain products, services or
              promotions.
            </li>
            <li>
              <b>With Your consent: </b>We may disclose Your personal
              information for any other purpose with Your consent.
            </li>
          </ul>
          <h2>Retention of Your Personal Data</h2>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
            <br />
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h2>Transfer of Your Personal Data</h2>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
            <br />
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
            <br />
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h2>Disclosure of Your Personal Data</h2>
          <h3>Law enforcement</h3>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h3>Other legal requirements</h3>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
          <h2>Security of Your Personal Data</h2>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h2>Children's Privacy</h2>
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
            <br />
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <h2>Links to Other Websites</h2>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third-party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
            <br />
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third-party sites or
            services.
          </p>
          <h2>Changes to this Privacy Policys</h2>
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
            <br />
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
            <br />
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <ul>
            <li>
              {/* By visiting this page on our website:  */}
              <a href="https://discord.gg/QGayCqh7QA" target="_blank">Connect with us</a>
            </li>
            {/* <li>
              By sending us an email: <a>support@openmesh.io</a>
            </li> */}
          </ul>
        </div>
     </Content>
  );
}
export default PrivacyPolicy;
