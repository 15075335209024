import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "./Components/landing-page/landing-page"
import Navbar from "./Components/navbar/navbar";
import ExploreMore from "./Components/explore-more/explore-more-component"
import FooterMain from "./Components/footer/footer";
import PrivacyPolicy from "./Components/privacy-policy/privacy-policy-component";
import ComingSoon from "./Components/coming-soon/coming-soon"
function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <Switch>
          <Route path="/:privacy-policy">
            <PrivacyPolicy/>
          </Route>
          <Route path="/:explore-more">
            <ExploreMore/>
          </Route>
          <Route path="/:coming-soon">
            <ComingSoon />
          </Route>
          <Route path="/">
            <LandingPage/>
          </Route>
        </Switch>
        <FooterMain/>
      </Router>
    </div>
  );
}

export default App;
